<template>
  <div class="homeBox">
    <div class="home-banner">
      <el-carousel style="pointer-events:none;">
        <el-carousel-item v-for="item in bannerList" :key="item">
          <img :src="item" alt="">
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="home-house">
      <div class="title">
        <h3>家政服务</h3>
        <i></i>
        <span>HOUSEKEEPING SERVICES</span>
      </div>
      <div class="home-house-cont">
        <ul class="house-cont-left">
          <li 
          v-for="item in housekeepingLeftList.list" 
          :key="item.id" 
          @mouseenter="housekeepingEvt(item.id)"
          :class="[{houseImg:housekeepingLeftList.currentIndex === item.id}]"
          >
            <div>
              <h5>{{item.title}}</h5>
              <span>{{item.text}}</span>
            </div>
            <article :style="`background:url(${item.path})`"></article>
          </li>
        </ul>
        <ul class="house-cont-right">
          <li 
          v-for="item in housekeepingRightList.list" 
          :key="item.id" 
          @mouseenter="housekeepingEvt(item.id)"
          :class="[{houseImg:housekeepingRightList.currentIndex === item.id}]"
          >
            <div>
              <h5>{{item.title}}</h5>
              <span>{{item.text}}</span>
            </div>
            <article :style="`background:url(${item.path})`"></article>
          </li>
        </ul>
      </div>
    </div>
    <div class="home-advantage">
      <div class="title">
        <h3>我们的优势</h3>
        <i></i>
        <span>OUR STRENGTHS</span>
      </div>
      <div class="home-advantage-cont">
            <ul class="advantage-cont-left">
            <li 
              v-for="item in advantageLeft.list" 
              :key="item.id"
              @mouseenter="advantageEvt(item.path,item.ipath,item.text,item.nextText)"
            >
                {{item.title}}
              <i></i>
            </li>
            </ul>
            <div class="home-advantage-image">
                <img :src='this.advantageMid.advantageImg' alt="">
                <div>
                    <i :style="{background:'url('+this.advantageMid.advantageiImg+')'}"></i>
                    <span>{{this.advantageMid.advantageText}}</span>
                    <span>{{this.advantageMid.advantageNextText}}</span>
                </div>
            </div>
            <ul class="advantage-cont-right">
              <li 
              v-for="item in advantageRight.list" 
              :key="item.id"
              @mouseenter="advantageEvt(item.path,item.ipath,item.text,item.nextText)"
              >
                  {{item.title}}
                <i></i>
              </li>
            </ul>
      </div>
    </div>
    <!-- <div class="home-training">
      <div class="title">
        <h3>培训视频</h3>
        <i></i>
        <span>TRAINING VIDEO</span>
      </div>
      <div class="home-training-cont">
          <div>
            <div>
                <figure><img src="https://laomaaijia.com/img/home/video8.png" alt="">
                <i></i></figure>
                <figcaption>
                    <h5>家政保洁规范培训</h5>
                    <span>主讲：刘老师</span>
                </figcaption>
            </div>
            <div>
                <figure><img src="https://laomaaijia.com/img/home/video7.png" alt="">
                <i></i></figure>
                <figcaption>
                    <h5>家务工作内容详细讲解</h5>
                    <span>主讲：丹丹老师</span>
                </figcaption>
            </div>
          </div>
          <div>
            <div>
                <figure><img src="https://laomaaijia.com/img/home/video5.png" alt="">
                <i></i></figure>
                <figcaption>
                    <h5>室外高空作业安全细则</h5>
                    <span>主讲：郭老师</span>
                </figcaption>
            </div>
            <div>
                <figure><img src="https://laomaaijia.com/img/home/video6.png" alt="">
                <i></i></figure>
                <figcaption>
                    <h5>月嫂家政保姆入门</h5>
                    <span>主讲：范老师</span>
                </figcaption>
            </div>
          </div>
      </div>
    </div> -->
    <!-- <div class="home-news">
      <div class="title">
        <h3>行业资讯</h3>
        <i></i>
        <span>INDUSTRY INFORMATION</span>
      </div>
      <div class="home-news-cont">
              <div @click="goNews(it.title,it.cover_url,it.content)" v-for="it in allData" :key="it.id">
                  <figcaption>
                      <div>
                        <h5>{{it.updatedAt.date.slice(5,10)}}</h5>
                        <h6>{{it.updatedAt.date.slice(0,4)}}</h6>
                      </div>
                      <span>{{it.title}}</span>
                  </figcaption>
                  <figure><img :src="it.cover_url" alt="">
                  <i></i></figure>
              </div>
          </div>
        <div class="getMore" @click="getMore">查看更多</div>
    </div> -->
  </div>
</template>

<script>
import { getArticle } from '../../aips/article'
export default {
  data() {
    return {
      //轮播图
      bannerList:[
        'https://laomaaijia.com/img/home/banner1.jpeg',
        'https://laomaaijia.com/img/home/banner2.jpeg',
        // require('https://laomaaijia.com/img/home/banner1.png')
      ],
      //家政服务左侧data
      housekeepingLeftList:{
        currentIndex:0,
        list:[
        {
          id:'0',
          title:'开荒保洁',
          text:'装修硬装结束第一次保洁 新居开荒',
          path:'https://laomaaijia.com/img/home/housekeeping1.jpeg'
        },
        {
          id:'1',
          title:'日常保洁',
          text:'钟点工  小时工  清洁护理',
          path:'https://laomaaijia.com/img/home/housekeeping2.jpeg'
        },
        {
          id:'2',
          title:'家电清洗',
          text:'给家电一个全面清洗',
          path:'https://laomaaijia.com/img/home/housekeeping3.jpeg'
        }
      ]
      },
      //家政服务右侧data
      housekeepingRightList:{
        currentIndex:10,
        list:[
        {
          id:'3',
          title:'深度保洁',
          text:'钟点工  小时工  清洁护理',
          path:'https://laomaaijia.com/img/home/housekeeping4.jpeg'
        },
        {
          id:'4',
          title:'搬家',
          text:'优质的服务，还您一个温馨的家',
          path:'https://laomaaijia.com/img/home/housekeeping5.jpeg'
        },
        {
          id:'5',
          title:'擦玻璃',
          text:'专业清洗，明亮窗外色',
          path:'https://laomaaijia.com/img/home/housekeeping6.jpeg'
        }
        ]
      },
      //我们的优势左侧data
      advantageLeft:{
        currentIndex:0,
        list:[
          {
            id:'1',
            title:'实名认证',
            text:'实名认证、严格筛选，彼此了解交流',
            nextText:'让您更放心',
            ipath:'https://laomaaijia.com/img/home/i1.png',
            path:'https://laomaaijia.com/img/home/advantage1.jpeg'
          },
          {
            id:'2',
            title:'专业培训',
            text:'服务人员系统培训、专业教学指导',
            nextText:'择优录取、服务更加专业',
            ipath:'https://laomaaijia.com/img/home/i2.png',
            path:'https://laomaaijia.com/img/home/advantage2.jpeg'
          },
          {
            id:'3',
            title:'家政保险',
            text:'提供人身保险及用户物品保障',
            nextText:'人身意外险、让服务全程无忧',
            ipath:'https://laomaaijia.com/img/home/i3.png',
            path:'https://laomaaijia.com/img/home/advantage3.jpeg'
          }
        ]
      },
      //我们的优势右侧data
      advantageRight:{
        currentIndex:10,
        list:[
          {
            id:'4',
            title:'装备规范',
            text:'定制型工具套装、安全实用',
            nextText:'自用自备、专业高效',
            ipath:'https://laomaaijia.com/img/home/i4.png',
            path:'https://laomaaijia.com/img/home/advantage4.jpeg'
          },
          {
            id:'5',
            title:'价格透明',
            text:'收费规则简单、明码标价',
            nextText:'公开透明、拒绝霸王条款',
            ipath:'https://laomaaijia.com/img/home/i5.png',
            path:'https://laomaaijia.com/img/home/advantage5.jpeg'
          },
          {
            id:'6',
            title:'评价反馈',
            text:'您反馈的意见我们会认真听取',
            nextText:'您的满意，就是我们的动力',
            ipath:'https://laomaaijia.com/img/home/i6.png',
            path:'https://laomaaijia.com/img/home/advantage6.jpeg'
          }
        ]
      },
      //我们的优势中间图片信息
      advantageMid:{
        advantageImg:'https://laomaaijia.com/img/home/advantage1.jpeg',
        advantageiImg:'https://laomaaijia.com/img/home/i1.png',
        advantageText:'实名认证、严格筛选，彼此了解交流',
        advantageNextText:'让您更放心'
      },
      //资讯数据
      allData:[],
      //资讯日期
    }
  },
  async created(){
      // this.housekeepingEvt(0)
      let res = await getArticle({type_name:'INDUSTRY'})
      this.allData =  res.data.splice(0,3)
      console.log(this.allData)
  },
  mounted(){
    this.housekeepingEvt("0")
  },
  methods: {
    //家政服务tab切换
    housekeepingEvt(it){
      if(it <= 2){
        this.housekeepingRightList.currentIndex = null
        this.housekeepingLeftList.currentIndex = it
      }else{
        this.housekeepingLeftList.currentIndex = null
        this.housekeepingRightList.currentIndex = it
      }
    },
    //我们的优势tab切换
    advantageEvt(path,ipath,text,nextText){
      this.advantageMid.advantageImg = path
      this.advantageMid.advantageiImg = ipath
      this.advantageMid.advantageText = text
      this.advantageMid.advantageNextText = nextText
    },
    //跳转资讯详情页
    async goNews(title,image,content){
      this.$store.commit("common/mutationTitle",title)
      this.$store.commit("common/mutationImage",image)
      this.$store.commit("common/mutationContent",content)
      this.$router.push('/news')
    },
    getMore(){
      this.$router.push('/newsCenter')
    }
  }
};
</script>

<style lang="less" scoped>
    @import "../../style/home.less";
</style>
